.editor-wrapper {
  height: calc(100vh - 50px);
  overflow: scroll;
  overflow-x: hidden;
  padding-top: 75px;
}

/* .ce-toolbar .ce-toolbox {
  font-size: 18px;
}

.ce-block--focused {
  min-height: 40px;
}

.ce-settings {
  display: flex;
  flex-direction: column;
  min-width: auto;
  top: -48px;
} */

.ce-settings__plugin-zone {
  min-width: 116px;
}
