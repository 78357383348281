$grey: #847979;
$blue: #2484ff;
$black: #000000;
$bg-dark-turquoise: #3c8dbc;
$bg-dirty-dark-green: #222d32;
$red: #ff0000;
$dirty-white: #dcdfe6;
$light-blue: #9b9bca;
$white: #ffffff;
$text-white: #ffffff;
$opacity-blue: rgba(188, 200, 255, 0.432);
