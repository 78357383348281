.expander {
  background-color: transparent !important;
}

.expander__button-add {
  margin-left: 476px;
  margin-bottom: 13px;
  width: 25px;
  height: 25px;
  border: none;
  background-color: green;
  outline: none;
  cursor: pointer;
}

.expander-block {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.expander-block::after {
  content: '-';
  position: absolute;
  top: -17px;
  right: -75px;
  font-size: 48px;
}

.expander-block__content {
  margin-bottom: 8px;
  width: auto;
  height: 200px;
  border: 1px solid black;
  border-radius: 3px;
  overflow: hidden;
}

.expander-block__title {
  margin-bottom: 8px;
  width: auto;
  border: 1px solid black;
  border-radius: 3px;
}

.expander-block__description {
  margin-bottom: 8px;
  width: auto;
  border: 1px solid black;
  border-radius: 3px;
}

.expander-block__delete-button {
  margin-left: auto;
  width: 20px;
  height: 20px;
  border: none;
  background-color: red;
  cursor: pointer;
}
