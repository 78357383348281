@import 'styles/helpers/variables.scss';
.services-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 470px;
  min-height: 250px;
  padding: 20px;
  border-radius: 4px;
  border: solid 1px $grey;
  background-color: $white;
  font-size: 14px;
  margin-bottom: 20px;

  &__table {
    border-spacing: 0px 3px;
    margin-left: 5px;
    margin-top: auto;
    font-size: 14px;
  }

  &__title {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 500;
  }

  &__cell {
    vertical-align: top;

    &_left {
      white-space: nowrap;
      font-weight: 800;
      padding-right: 7px;
    }
  }

  &__button {
    outline: none;
    width: 100px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: $text-white;
    margin-top: 10px;
    background-color: $bg-dark-turquoise;
  }
}
